
export const extraTransactionList:any =   {"DAS Lite":{
    "order": [
        "action",
        "Date",
        "amount",
        "status",
        "ProductType",
        "Scheme",
        "TransactionType",
        "CurrencyCode"
    ],
    "unChecked": [
        "uuid",
        "TransactionID",
        "LegalName",
        "LegalNameInEnglish",
        "UpdatedDate",
        "DASMID",
        "has3DS",
        "AcquirerCode",
        "CardNumber",
        "MerchantRefID",
        "trackID",
        "AcquirerMID",
        "AuthCode",
        "SubscriptionId",
        "TerminalId",
        "TerminalName",
        "PBLLinkName"
    ],
    "updatedList": [
        {
            "field": "Date",
            "headerName": "Transaction Date",
            "id": 7
        },
        {
            "field": "amount",
            "headerName": "Amount",
            "id": 5
        },
        {
            "field": "status",
            "headerName": "Status/Type",
            "id": 6
        },
        {
            "field": "ProductType",
            "headerName": "Product Type",
            "id": 20
        },
        {
            "field": "Scheme",
            "headerName": "Scheme",
            "id": 10
        },
        {
            "field": "TransactionID",
            "headerName": "Transaction ID",
            "id": 1
        },
        {
            "field": "uuid",
            "headerName": "Transaction Ref ID",
            "id": 2
        },
        {
            "field": "LegalName",
            "headerName": "Merchant Account",
            "id": 3
        },
        {
            "field": "LegalNameInEnglish",
            "headerName": "Merchant Account (English)",
            "id": 4
        },
        {
            "field": "UpdatedDate",
            "headerName": "Update Date",
            "id": 8
        },
        {
            "field": "DASMID",
            "headerName": "DAS MID",
            "id": 9
        },
        {
            "field": "has3DS",
            "headerName": "Integration Method",
            "id": 11
        },
        {
            "field": "AcquirerCode",
            "headerName": "Acquirer",
            "id": 12
        },
        {
            "field": "TransactionType",
            "headerName": "Transaction Type",
            "id": 13
        },
        {
            "field": "CardNumber",
            "headerName": "Card Number",
            "id": 14
        },
        {
            "field": "MerchantRefID",
            "headerName": "Merchant Ref ID",
            "id": 15
        },
        {
            "field": "trackID",
            "headerName": "Track ID",
            "id": 16
        },
        {
            "field": "AcquirerMID",
            "headerName": "Acquirer MID",
            "id": 17
        },
        {
            "field": "AuthCode",
            "headerName": "Auth Code",
            "id": 18
        },
        {
            "field": "CurrencyCode",
            "headerName": "Currency",
            "id": 19
        },
        {
            "field": "SubscriptionId",
            "headerName": "Subscription ID",
            "id": 21
        },
        {
            "field": "TerminalId",
            "headerName": "Terminal ID",
            "id": 22
        },
        {
            "field": "TerminalName",
            "headerName": "Terminal Name",
            "id": 23
        },
        {
            "field": "PBLLinkName",
            "headerName": "Link Name",
            "id": 24
        }
    ]
  }};
  
  export const extraStatementList:any = {
    "DAS Lite":{
      "order": [
          "action",
          "Date",
          "DASMID",
          "StatementID",
          "amount"
      ],
      "unChecked": [
          "ROLLING_RESERVE_HELD",
          "ROLLING_RESERVE_HELD_AMOUNT",
          "ROLLING_RESERVE_RELEASED",
          "RollingReservesHeldYTDAmount",
          "FixedDepositHeldYTDAmount",
          "SettlementStatus",
          "WiredStatus",
          "ReleasedDate",
          "Payout_Date",
          "AdjustmentsAmount",
          "NetSales",
          "TotalFees",
          "TRANSACTION_START_DATE",
          "TRANSACTION_END_DATE"
      ],
      "updatedList": [
          {
              "field": "Date",
              "headerName": "Statement Date",
              "id": 1
          },
          {
              "field": "DASMID",
              "headerName": "DAS MID",
              "id": 2
          },
          {
              "field": "StatementID",
              "headerName": "Statement ID",
              "id": 3
          },
          {
              "field": "amount",
              "headerName": "Total Payout",
              "id": 4
          },
          {
              "field": "ROLLING_RESERVE_HELD",
              "headerName": "Rolling Reserve (%)",
              "id": 5
          },
          {
              "field": "ROLLING_RESERVE_HELD_AMOUNT",
              "headerName": "Rolling Reserve Held",
              "id": 6
          },
          {
              "field": "ROLLING_RESERVE_RELEASED",
              "headerName": "Rolling Reserve Released",
              "id": 7
          },
          {
              "field": "RollingReservesHeldYTDAmount",
              "headerName": "Cumulative Reserves",
              "id": 8
          },
          {
              "field": "FixedDepositHeldYTDAmount",
              "headerName": "Fixed Deposit Held",
              "id": 9
          },
          {
              "field": "SettlementStatus",
              "headerName": "Statement Status",
              "id": 10
          },
          {
              "field": "WiredStatus",
              "headerName": "Wired Status",
              "id": 11
          },
          {
              "field": "ReleasedDate",
              "headerName": "Released Date",
              "id": 12
          },
          {
              "field": "Payout_Date",
              "headerName": "Payout Date",
              "id": 13
          },
          {
              "field": "AdjustmentsAmount",
              "headerName": "Adjustment Amount",
              "id": 14
          },
          {
              "field": "NetSales",
              "headerName": "Net Sales",
              "id": 15
          },
          {
              "field": "TotalFees",
              "headerName": "Total Fees",
              "id": 16
          },
          {
              "field": "TRANSACTION_START_DATE",
              "headerName": "Transaction Start Date",
              "id": 17
          },
          {
              "field": "TRANSACTION_END_DATE",
              "headerName": "Transaction End Date",
              "id": 18
          }
      ]
  }
  };