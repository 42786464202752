
export const dateFormatOptions:any = [
    {
      label: 'DD MONTH YYYY, hh:MM:SS AM/PM (Example: 21 November 2024, 2:04:28 PM)',
      value: 'dd MMMM yyyy, hh:mm:ss a', 
    },
    {
      label: 'DD MONTH YYYY, HH:MM:SS (Example: 21 November 2024, 14:04:28)',
      value: 'dd MMMM yyyy, HH:mm:ss', 
    },
    {
      label: 'YYYY/MM/DD HH:MM:SS (Example: 2024/11/21 14:04:28)',
      value: 'yyyy/MM/dd HH:mm:ss', 
    },
  ];


  export const DATE_FORMATS_OPTIONS : any = {
    DATE_TIME_AM_PM: 'dd MMMM yyyy, hh:mm:ss a',
    DATE_TIME_DMY: 'dd MMMM yyyy, HH:mm:ss',
    DATE_TIME_YMD: 'yyyy/MM/dd HH:mm:ss',
  };
